import React from 'react'
import imgHero from 'img/home-hero.svg'
import MarkdownRenderer from '../MarkdownRenderer/MarkdownRenderer'

import './Hero.scss'

const Hero = ({ description, subDescription, children }) => {
	return (
		<section className='hero-wrapper'>
			<div className='hero'>
				<div className='hero-content'>
					<h1>
						<div className='hero-title hero-mobile-title-flair'>
							Human-enabled
						</div>
						<div className='hero-subtitle hero-subtitle-flair'>
							Machine-driven
						</div>
					</h1>
					<h2 className='hero-description'>
						<MarkdownRenderer>{description}</MarkdownRenderer>
					</h2>
				</div>
				<div className='hero-image'>
					<img
						src={imgHero}
						alt='Ordaos'
						className='hero-image-svg'
					/>
					<div className='hero-content--mobile'>
						<h1 className='hero-title'>
							<div>Human-enabled</div>
						</h1>
						<h2 className='hero-subtitle'>
							<div>Machine-driven</div>
						</h2>
					</div>
				</div>
				<div className='hero-cta-section--mobile'>
					<h2 className='hero-description'>
						<MarkdownRenderer>{description}</MarkdownRenderer>
					</h2>
				</div>
			</div>
		</section>
	)
}

export default Hero
