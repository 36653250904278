import React from 'react'
import cx from 'classnames'
import { RichText } from '@ordaos/nucleus'
import CMS from '../../components/CMS/CMS'

import './Content.scss'

const Content = ({
	title,
	flair,
	float = 'left',
	img,
	children,
	className,
	inverted,
}) => {
	const classNames = cx('content-wrapper', {
		[`content-wrapper--${className}`]: className,
		[`content-wrapper--float-${float}`]: float,
		[`content-wrapper--flair`]: flair,
		[`content-wrapper--inverted`]: inverted,
	})

	return (
		<div className={classNames}>
			<div className='content'>
				<RichText inverted={inverted}>
					{title && <h2 className='content--title'>{title}</h2>}
					<div className='content--text'>{children}</div>
				</RichText>
			</div>
			{img && <CMS.Image {...img} />}
		</div>
	)
}

export default Content
