import React from 'react'
import { Remarkable } from 'remarkable'
import RemarkableReactRenderer from 'remarkable-react'

const md = new Remarkable()
md.renderer = new RemarkableReactRenderer()

const MarkdownRenderer = ({ children }) => {
	return md.render(children)
}

export default MarkdownRenderer
