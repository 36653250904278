import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from '@ordaos/nucleus'

import Layout from '../layout/BasicPage'
import Content from '../layout/Content'

import Link from '../components/Link'
import Hero from '../components/Hero'
import scalable from 'img/scalable.png'
import repeatable from 'img/repeatable.png'
import automated from 'img/automated.png'
import agnostic from 'img/agnostic.png'
import perceptive from 'img/perceptive.png'
import efficacy from 'img/efficacy.png'
import value from 'img/value.png'
import ipProtected from 'img/ip-protected.png'
import Section from '../components/Section'
import CMS from '../components/CMS'
import SEO from '../components/SEO'

import MarkdownRenderer from '../components/MarkdownRenderer/MarkdownRenderer'

import './index.scss'

const IndexPage = ({ data }) => {
	const { press, homePage, posters } = data
	const {
		welcome,
		whatWeDo,
		firstSection,
		secondSection,
		designEngine,
		boardMembers,
	} = homePage.childMarkdownRemark.frontmatter

	return (
		<Layout>
			<SEO {...SEO.metadata(homePage)} />
			<div className='home'>
				<Hero description={welcome.description}></Hero>
				<Section
					title={firstSection.title}
					subtitle={firstSection.subtitle}
					inverted
				></Section>
				<Section invertedDark width='full'>
					<Content
						title={whatWeDo.firstSection.title}
						img={whatWeDo.firstSection.image}
						inverted
					>
						<MarkdownRenderer>
							{whatWeDo.firstSection.description}
						</MarkdownRenderer>
						<Link to={whatWeDo.firstSection.link}>Learn more</Link>
					</Content>
				</Section>
				<Section invertedDark width='full'>
					<Content
						title={whatWeDo.secondSection.title}
						img={whatWeDo.secondSection.image}
						inverted
						float='right'
					>
						<MarkdownRenderer>
							{whatWeDo.secondSection.description}
						</MarkdownRenderer>
						<Link to={whatWeDo.secondSection.link}>Learn more</Link>
					</Content>
				</Section>
				<Section className='denovo-design' invertedDark width='full'>
					<Content
						title={whatWeDo.thirdSection.title}
						img={whatWeDo.thirdSection.image}
						inverted
					>
						<MarkdownRenderer>
							{whatWeDo.thirdSection.description}
						</MarkdownRenderer>
						<Link to={whatWeDo.thirdSection.link}>Learn more</Link>
					</Content>
				</Section>
				<Section inverted width='full' id='minipro'>
					<Content
						className='minipro-proteins'
						title={secondSection.title}
						flair
						img={secondSection.image}
					>
						<MarkdownRenderer>
							{secondSection.subtitle}
						</MarkdownRenderer>
						<ul>
							<li key='modular'>Configurable building block</li>
							<li key='small'>
								20x smaller than traditional monoclonal
								antibodies (40-160 amino acids) enabling them to
								more easily penetrate disease microenvironments
								for effective treatment
							</li>
							<li key='thermostability'>
								Sound structure with improved thermostability
								and solubility, and minimized aggregation and
								other developability risks to ensure the ease of
								manufacturing
							</li>
							<li key='configurable'>
								Configurable binding, optimized for on-target vs
								off-target binding for selected epitope, with
								tuned affinity range and configurable
								isoelectric point
							</li>
							<li key='humanized'>
								Humanized and optimized to meet client-specific
								MHC / peptide profiles to minimize
								immunogenicity
							</li>
						</ul>
					</Content>
				</Section>
				<Section invertedDark width='full'>
					<Content
						title={whatWeDo.fourthSection.title}
						img={whatWeDo.fourthSection.image}
						className='radar-graph'
						inverted
						float='right'
					>
						<MarkdownRenderer>
							{whatWeDo.fourthSection.description}
						</MarkdownRenderer>
						<Link to={whatWeDo.fourthSection.link}>Learn more</Link>
					</Content>
				</Section>
				<Section
					inverted
					className='design-engine'
					width='full'
					id='design-engine'
				>
					<Content
						title={designEngine.title}
						float='right'
						className='design-engine'
						img={designEngine.image}
					>
						<p>
							The Ordaōs Design Engine is a multitask
							meta-learning model that leverages continuous
							learning loops and both public and proprietary data
							sets to translate human-defined product criteria
							into machine-designed miniPRO™.
						</p>
						<p>
							Starting with amino acids, the engine generates,
							appraises, and ranks billions of protein sequences,
							hundreds of thousands of protein structures and
							other drug-like properties to create miniPRO™.
						</p>
						<p>
							miniPRO™ are evaluated to provide intelligent
							feedback on multiple design objectives including
							protein structure, binding specificity and affinity,
							solubility, stability, immunogenicity, and
							developability. The analysis feeds the engine within
							a generative AI reinforcement learning loop, to
							iteratively improve and deliver optimal miniPRO™
							that meet the client’s molecular target product
							profile (mTPP).
						</p>
					</Content>
				</Section>
				<Section className='benefits' title='Benefits of Ordaōs System'>
					<ul className='benefits'>
						<li>
							Scalable <img src={scalable} />
						</li>
						<li>
							Repeatable <img src={repeatable} />
						</li>
						<li>
							Highly Automated <img src={automated} />
						</li>
						<li>
							Disease Agnostic <img src={agnostic} />
						</li>
						<li>
							Perceptive System <img src={perceptive} />
						</li>
						<li>
							Efficacy <img src={efficacy} />
						</li>
						<li>
							Time to Value <img src={value} />
						</li>
						<li>
							IP Protected <img src={ipProtected} />
						</li>
					</ul>
				</Section>
				<Section
					className='quote'
					subtitle='<i>"You accomplished in four-days what took us two-years to accomplish."</i>'
					inverted
				>
					<div>Dr. Philip Howe, Chair, Department of Chemistry,</div>
					<div>Medical University of South Carolina</div>
				</Section>
				<Section className='partner' invertedDark width='full'>
					<Content
						title={whatWeDo.fifthSection.title}
						img={whatWeDo.fifthSection.image}
						inverted
					>
						<p>
							By maximizing drug candidate delivery speed,
							novelty, and probability of clinical success, we can
							provide the highest client confidence in every
							investigational new drug (IND) application.
						</p>
						<p>
							What’s more, our clients see a return on their
							investment in weeks, rather than months or years.
						</p>
						<Link to={whatWeDo.fifthSection.link}>Contact us</Link>
					</Content>
				</Section>
				<Section
					className='quote'
					subtitle='<i>"The AI-based drug design engine Ordaōs has developed is a method with game-changing potential."</i>'
					inverted
				>
					<div>Dr. Ülo Palm, Chief Medical Officer, Vaxxinity</div>
				</Section>
				<Section className='board-members'>
					{boardMembers.map((logo) => {
						return <CMS.Image {...logo} />
					})}
					<div className='jlabs '>
						Ordaos is a resident company of Johnson & Johnson
						Innovation –{' '}
						<a
							href='https://jnjinnovation.com/JLABSNavigator/company/Ordaos'
							target='_blank'
						>
							JLABS
						</a>
					</div>
				</Section>
				<Section
					title='Ordaōs in the News'
					subtitle='We are in the news because we’re making huge strides in human-enabled, machine-driven drug design.'
					className='news'
				>
					{press.articles.map(({ result }) => {
						return (
							<PressRelease
								key={result.article.data.title}
								title={result.article.data.title}
								date={result.article.data.date}
								description={result.article.data.description}
								to={`/press/${result.link.replace(
									/\.md$/,
									''
								)}`}
							/>
						)
					})}
				</Section>
				<Section
					className='posters'
					title='Posters'
					subtitle='Read abstracts and sign up to download more info'
				>
					<ul>
						{posters?.forms &&
							posters.forms.map(({ poster }) => {
								return (
									<li className='poster'>
										<Link
											to={`press/${poster.link.replace(
												/\.md$/,
												''
											)}`}
										>
											{
												poster.childMarkdownRemark.data
													.title
											}
										</Link>
										<CMS.Image
											{...poster.childMarkdownRemark.data
												.photo}
										/>
										<CMS.Body {...poster} />
									</li>
								)
							})}
					</ul>
				</Section>
			</div>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query Home {
		homePage: file(relativePath: { eq: "home.md" }) {
			...SEOFragment
			childMarkdownRemark {
				frontmatter {
					welcome {
						description
					}
					whatWeDo {
						firstSection {
							title
							description
							link
							image {
								...CMSImageFragment
							}
						}
						secondSection {
							title
							description
							link
							image {
								...CMSImageFragment
							}
						}
						thirdSection {
							title
							description
							link
							image {
								...CMSImageFragment
							}
						}
						fourthSection {
							title
							description
							link
							image {
								...CMSImageFragment
							}
						}
						fifthSection {
							title
							body
							link
							image {
								...CMSImageFragment
							}
						}
					}
					firstSection {
						title
						subtitle
					}
					secondSection {
						title
						subtitle
						image {
							...CMSImageFragment
						}
					}
					thirdSection {
						firstGraphic {
							title
							subtitle
						}
						secondGraphic {
							title
							subtitle
						}
						thirdGraphic {
							title
							subtitle
						}
					}
					fifthSection {
						title
						threeUp {
							firstGraphic {
								title
								subtitle
							}
							secondGraphic {
								title
								subtitle
							}
							thirdGraphic {
								title
								subtitle
							}
						}
					}
					designEngine {
						title
						image {
							...CMSImageFragment
						}
					}
					boardMembers {
						...CMSImageFragment
					}
				}
			}
		}
		posters: allFile(
			filter: {
				sourceInstanceName: { eq: "lead-posters" }
				extension: { eq: "md" }
			}
		) {
			forms: edges {
				poster: node {
					...CMSFragment
					link: relativePath
					childMarkdownRemark {
						data: frontmatter {
							title
							description
							photo {
								...CMSImageFragment
							}
						}
					}
				}
			}
		}
		press: allFile(
			filter: {
				sourceInstanceName: { eq: "press" }
				extension: { eq: "md" }
			}
			sort: {
				fields: childrenMarkdownRemark___frontmatter___date
				order: DESC
			}
		) {
			articles: edges {
				result: node {
					link: relativePath
					article: childMarkdownRemark {
						data: frontmatter {
							date(formatString: "yyyy/MM/DD")
							description
							title
						}
					}
				}
			}
		}
	}
`

const StatCard = ({ title, subtitle, img }) => {
	return (
		<div className='stat-card'>
			<RichText>
				<p>{subtitle}</p>
			</RichText>
			<img src={img} alt={title} />
		</div>
	)
}

const HelpCard = ({ to, title, description, img }) => {
	return (
		<div to={to} className='card'>
			<RichText>
				<h3 className='card--title'>{title}</h3>
				<img src={img} alt={title} />
				<p className='small'>{description}</p>
			</RichText>
		</div>
	)
}

const PressRelease = ({ title, date, to }) => {
	return (
		<li className='release'>
			<span className='release-date'>
				{new Date(date).toLocaleDateString()}
			</span>
			<Link to={to}>{title}</Link>
		</li>
	)
}
